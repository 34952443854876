import type { NextPage } from 'next';
import * as React from 'react';
import Link from 'next/link';
import api from '../http/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataGrid, GridRowParams, GridActionsCellItem } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import ReplayIcon from '@mui/icons-material/Replay';
import toast from 'react-hot-toast';
import { CompanyLoader } from 'ui';

const fetchBatches = async () => {
  return api.get('/batches').then((r) => r.data);
};

const retryBatch = async (batchId: string) => {
  return api.post(`/batch/${batchId}/retry`).then((r) => r.data);
};

const BatchesList: NextPage = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['batches'],
    queryFn: fetchBatches,
  });

  const retry = useMutation({ mutationFn: retryBatch });

  const columns = React.useMemo(
    () => [
      { field: 'user', headerName: 'Usuario' },
      { field: 'app', headerName: 'App' },
      { field: 'jobName', headerName: 'Job' },
      { field: 'friendlyName', headerName: 'Nombre' },
      { field: 'status', headerName: 'Estado' },
      { field: 'completedChildren', headerName: 'Completados' },
      { field: 'children', headerName: 'Total' },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Link href={`batch/${params.row.id}`}>
                <InfoIcon titleAccess="Ver" />
              </Link>
            }
            label="Ver"
          />,
          <GridActionsCellItem
            icon={<ReplayIcon titleAccess="Reintentar fallidos" />}
            disabled={retry.isPending}
            onClick={() =>
              retry
                .mutateAsync(params.row.id)
                .then((r) => toast.success(`Reintentando ${r.length} jobs`))
            }
            label="Reintentar fallidos"
          />,
        ],
      },
    ],
    [retry]
  );

  if (isLoading) {
    return <CompanyLoader />;
  }

  if (error) {
    return <>Ocurrió un error: ${JSON.stringify(error)}</>;
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100 },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection
      />
    </div>
  );
};

export default BatchesList;
